import Permission from "@/permission/index";

export default class CardPermission extends Permission {
    static card() {
        return super.getUserPermission('cards')
    }

    static getCardPermission(property) {
        const splitProperty = property.split('.')
        const [one, two] = splitProperty
        if (this.hasAdminRole()) return true
        if (splitProperty.length > 1) {
            return this.card()[one][two] ?? false
        } else {
            return this.card()[one] ?? false
        }
    }

    static getCardCreatePermission() {
        return this.getCardPermission('create')
    }

    static getCardViewPermission() {
        return this.getCardPermission('view')
    }

    static getCardEditPermission() {
        return this.getCardPermission('update')
    }

    static getCardDeletePermission() {
        return this.getCardPermission('delete')
    }

    static getCardResendVisaPermission() {
        return this.getCardPermission('resend_visa')
    }

    static getCardViewLogsPermission() {
        return this.getCardPermission('view_logs')
    }
}
